import { MouseEvent } from 'react'

import clsx from 'clsx'

import { NavigationContentSingleColumn } from './NavigationContentSingleColumn'

type SubnavLink = {
	icon?: { url?: string }
	text?: string
	subtext?: string
	LinkHoverColor?: string
	link?: {
		href: string
		onClick(event: MouseEvent): void
		target?: '_self' | '_blank'
	}
}
export type MegaMenuProps = {
	className?: string
	subtitle?: string
	divider?: boolean
	subnavLinks?: SubnavLink[]
}

export function NavigationContentColumn({
	className,
	subtitle,
	subnavLinks,
	divider
}: MegaMenuProps) {
	return (
		<div
			className={clsx('w-full h-min flex flex-col text-gray-500', className)}
			data-testid="megamenu-column"
		>
			<div className="w-full  pointer h-min">
				<NavigationContentSingleColumn
					subtitle={subtitle}
					divider={divider}
					subnavLinks={subnavLinks}
				/>
			</div>
		</div>
	)
}
